<template>
  <div>
    <div class="exam-content-section" v-if="displayTab === 'exam'">
      <div class="row">
        <div class="col-9">
          <div class="exam-description">
            <div class="header-description">
              <h1>Exam</h1>
              <p>
                Get information about the exam results for the current academic year. With improved analytics, you may view and download exam records for a single exam or for all exams at once.
              </p>
            </div>

            <div class="exam-marks">
              <div class="exam-partitions">
                <div class="exam-scores">
                  <h1>{{ student_exam_details.total_gpa }}</h1>
                  <h2>GPA</h2>
                </div>

                <div class="exam-scores">
                  <h1>{{ student_exam_details.final_rank }}</h1>
                  <h2>Rank</h2>
                </div>

                <div class="exam-scores">
                  <h1 >{{student_exam_details.exam_date}}</h1>
                  <h2>Exam Date</h2>
                </div>

                <div class="exam-scores">
                  <h1>{{student_exam_details.full_marks}}</h1>
                  <h2>Full Marks</h2>
                </div>

                <div class="exam-scores">
                  <h1>{{student_exam_details.total_obtained_marks}}</h1>
                  <h2>Total Scored</h2>
                </div>
              </div>

              <div class="exam-term">
                <a href="javascript:void(0);">
                  <h1>{{student_exam_details.exam_name.text || "Exam Name"}}</h1>
                </a>
              </div>
            </div>

            <div class="analysis-status">
              <div class="download-status">
                <h1 @click="printRoutine()" >
                  
                  <a href="javascript:void(0);" :class="exam_id === '' ? 'isDisabled':''">
                    Download Routine
                  </a>
                </h1>
                <h1><a href="javascript:void(0);" @click="downloadLegger" :class="exam_id === '' ? 'isDisabled':''">Download Ledger</a></h1>

                <h1 @click="printResult()"><a href="javascript:void(0);" :class="exam_id === '' ? 'isDisabled':''">Download Result</a></h1>
              </div>

              <div class="exam-analysis">
                <a href="javascript:void(0);">
                  <h1>Download Overall Analysis</h1>
                </a>
              </div>
            </div>
          </div>

          <div class="scored-marks">
            <div class="scored-marks-header">
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="12">
                    <v-select
                      outlined
                      dense
                      :items="[{ value: 'all', text: 'All' }, ...exams]"
                      required
                      class="pa-0"
                      label="Select Exam"
                      v-model="exam_id"
                      @change="getScoredMarks()"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <div class="performance-analysis" v-if="selectType == 'marks'">
              <div class="student-performance">
                <h1>Performance Analysis</h1>
                <p>Student</p>

                <div class="analysis-score">
                  <div class="score" v-for="(studentPerformance , index) in student_performance" :key="index">
                    <h2>{{ studentPerformance.grade }}</h2>
                    <h1>{{studentPerformance.performance}}</h1>
                    <!-- {{ studentPerformance.grade.rankColorCode(studentPerformance.grade) }} -->
                    
                    <div class="content" :style="{ 'background-color': studentPerformance.grade.rankColorCode(studentPerformance.grade) }"></div>
                  </div>

                  <!-- <div class="score">
                    <h2>B+</h2>
                    <h1>Hygiene</h1>
                    <div
                      style="width: 200px; background-color: #74b773"
                      class="content"
                    ></div>
                  </div>
                  <div class="score">
                    <h2>B</h2>
                    <h1>Punctuality</h1>
                    <div
                      style="width: 156px; background-color: #ecd246"
                      class="content"
                    ></div>
                  </div>
                  <div class="score">
                    <h2>C+</h2>
                    <h1>Discipline</h1>
                    <div
                      style="width: 220px; background-color: #e25947"
                      class="content"
                    ></div>
                  </div> -->
                </div>
              </div>

              <div class="scored-marks-table">
                <h1>Scored Marks</h1>
                <table style="width: 100%">
                  <thead>
                    <tr>
                    <th style="border-left: 1px solid #e2e2e2" colspan="1"></th>
                    <th style="border-left: 1px solid #e2e2e2" colspan="1"></th>
                    <th style="border-left: 1px solid #e2e2e2" colspan="1"></th>
                    <th
                      style="border: 1px solid #e2e2e2; text-align: center"
                      colspan="2"
                    >
                      Obtained Gradess
                    </th>
                    <th colspan="1"></th>
                  </tr>
                  
                  <tr>
                    <th style="border-right: 1px solid #e2e2e2">S.N</th>
                    <th style="border-right: 1px solid #e2e2e2">Subjects</th>
                    <th style="border-left: 1px solid #e2e2e2">FM</th>
                    <th style="border: 1px solid #e2e2e2">TH</th>
                    <th style="border: 1px solid #e2e2e2">PR</th>
                    <th style="width: 14%">Grade Point</th>
                  </tr>

                  </thead>
                  <tbody v-for="(marksheet,index) in student_marksheets" :key="index">
                    <tr>
                      <td>{{ ++index }}.</td>
                      <td>{{marksheet.subject.name}}</td>
                      <td>{{marksheet.sub_fullmark}}</td>
                      <td>{{marksheet.oth_grade}}</td>
                      <td>{{marksheet.opr_grade }}</td>
                      <td>{{ marksheet.grade_point }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="certificate-section" v-if="selectType == 'crt'">
              <div class="certificate-template">
                <div class="certificate-icon">
                  <img src="images/img/badge.png" alt="" />
                </div>

                <div class="about-certificate">
                  <h1>Character Certificate</h1>

                  <div class="certificate-year">
                    <h2>From : 2073</h2>
                    <h2>To : 2079</h2>
                  </div>
                </div>
              </div>

              <div class="certificate-printer-section">
                 <div class="certificate-result">
                <div class="printer-icon">
                  <img src="images/img/printing.png" alt="" />
                </div>

                <div class="printer-content">
                  <h1>GPA: 3.2</h1>
                  
                </div>
                <div class="certificate-grade">
                  <span class="material-icons tick">
                  check_circle
                  </span>
                    <h1>Excellent</h1>
                  </div>
              </div>
              </div>
             
            </div>
          </div>
        </div>
        <div class="col-3 pl-0 pr-0 overlay" >
          <div class="exam-diagram">
            <h1>STUDENTS SCORED MARKS OVERVIEW</h1>
             <!-- <DonutChart></DonutChart> -->
             <apexchart
              :key="refreshcomKey"
              type="donut"
              width="250"
              :options="options"
              :series="series"
            ></apexchart>

            <div :class="student_marksheets.length > 0 ? '':'inner-overlay'">
              <div style="text-align: center">
                <div style="margin-top:100px;"></div>
                <!-- <v-icon style="font-size: 50px;color:red;"
                  >info</v-icon
                > -->
                <!-- <p style="font-size: 10px;margin-bottom:0;margin-top:2px;">STUDENTS SCORED MARKS OVERVIEW</p> -->
              </div>
            </div>

          <!-- <BarChart></BarChart> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import ls from "../../../../library/Storage"
import { passDataToExternalDomain,redirectDownloadUrl } from "../../../../library/helpers"
export default {
  props:{
    enrollCode:{},
    sectionId:{},
    gradeId:{},
    enrollId:{},
    gradeName:{},
  },
  computed: {
    ...mapState(['batch'])
  },
  data: () => ({
    refreshcomKey:0,
    exam_id:"",
    selectType: "marks",
    exams: [],

    displayTab: "exam",
    dueReport: "",

    series: [44, 55, 41, 17, 15],
    options: {
      legend: {
        show: true,
        position:"bottom"
      },
       dataLabels: {
        enabled: false
      },
      labels: [],
      
    },
   
    student_performance:null,
    student_marksheets:[],
    student_exam_details:{
      total_gpa:'',
      final_rank:'',
      exam_date:'',
      full_marks:'',
      total_obtained_marks:'',
      exam_name:{}
    }
  }),
  mounted() {
    this.getExams();
    
  },
  methods: {
    getExams(){
      this.$rest.get('/api/exam?is_aio=true')
      .then(({data})=>{
        this.exams = data.data.map(item => {
          return {value: item.id, text: item.name}
        });
      })
    },

   
    getScoredMarks(){
      if(this.exam_id == 'all'){
        this.student_exam_details.exam_name.text = 'All'
      }else{
        this.student_exam_details.exam_name = this.exams.find((exam) => exam.value === this.exam_id);
      }

      this.selectType = "marks";
      let queryParam = "enrollode="+this.enrollCode+
                        "&examId="+this.exam_id+
                        "&sectionId="+this.sectionId+
                        "&gradeId="+this.gradeId+
                        "&enrollId="+this.enrollId

      this.$rest.get('api/report/exam/marksheet?'+queryParam)
      .then((res)=>{  
          this.student_performance = res.data.performances;
          this.student_marksheets = res.data.marksheets
          this.options.labels = res.data.marksheets.map((e)=> {
            return e.subject.name
          })
          let total_marks_obained = res.data.marksheets.map((m)=>{
            if(m.opr_marks == "-"){
              return parseFloat(m.oth_marks)
            }else{
             return parseFloat(m.oth_marks) + parseFloat(m.oth_marks)
            }
          })
          let subject_full_marks  = res.data.marksheets.map((f)=>{
            return f.sub_fullmark
          })
          this.student_exam_details.total_gpa = res.data.summary.gpa
          this.student_exam_details.final_rank = res.data.summary.final_rank
          this.student_exam_details.full_marks = subject_full_marks.reduce((a,b)=> a+b);
          this.student_exam_details.total_obtained_marks = total_marks_obained.reduce((a,b)=> a+b);
          this.series = total_marks_obained;
          this.refreshcomKey += 1;

      })
      this.getExamDate();
      // this.$rest.get('http://reporting.test/download/exam/marksheet-ledger/1:24'+queryParam)
    },

    getExamDate(){
      this.$rest.get('/api/exam-routine?batchId='+this.batch.id +'&gradeId=' + this.gradeId + '&examId=' + this.exam_id +"&examDate=true")
      .then((res)=>{
        if(res.status === 200){
          if(res.data.length > 0){
            this.student_exam_details.exam_date = res.data
          }
        }
      })
    },
    
    printRoutine(){
      if(this.exam_id){
        passDataToExternalDomain('reporting')
        setTimeout(() => {
          const url = redirectDownloadUrl({uri:'/print/exam/exam-routine',queryString:`gradeId=${this.gradeId}&examId=${this.exam_id}&exam_name=${this.student_exam_details.exam_name.name}&grade_name=${this.gradeName}`});
        }, 200);

      }
    },
    printResult(){
      if(this.exam_id){
        passDataToExternalDomain('reporting')
        setTimeout(() => {
          const url = redirectDownloadUrl({uri:'/print/exam/marksheet',queryString:`gradeId=${this.gradeId}&examId=${this.exam_id}&sectionId=${this.sectionId}&enrollId=${this.enrollId}&print_type=single&type=gradesheet&templateId=&exam_type=exam`});
        }, 200);
      
      }

    },
    downloadLegger(){
      if(this.exam_id){
        passDataToExternalDomain('reporting')
        redirectDownloadUrl({
              uri: "/download/exam/marksheet-ledger",
              queryString: `examId=${this.exam_id}&gradeId=${this.gradeId}&sectionId=${this.sectionId}&print_type=terminal&type=grading&download=true`,
        });

      }
    }
    
  },
};
</script>
<style lang="scss">
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.overlay .inner-overlay p{
    margin-top: 40%;
}
</style>